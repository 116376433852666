export const ContainerStatusToString = (status: number): string => {
    switch (status) {
        case 0:
            return 'Nieznany';
        case 1:
            return 'Przyłączony';
        case 2:
            return 'Odłączony';
        case 3: 
            return 'Przygotowany do dostarczenia';
        case 4: 
            return 'Dostarczony';
        case 5: 
            return 'Do wymiany';
        case 6: 
            return 'Uszkodzony - Serwis Infinivo';
        case 7: 
            return 'Oczekiwanie na serwis';
        case 8: 
            return 'W trakcie serwisowania';
        case 9: 
            return 'Uszkodzony - naprawa niemożliwa'
        default:
            return 'Błąd';
    }
}